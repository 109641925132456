<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>
                        {{activeLanguage.title}}
                        <router-link :to="`/order/${group.invoiceNr}`">{{group.invoiceNr}}</router-link>
                    </h1>
                </div>
            </div>
        </div>
        <div class="container" style="margin-top: 20px;">
            <div class="row">
                <div class="col-12">
                    <h2>
                        {{activeLanguage.infoTable.title}}
                        <router-link to="/dispute/employee/group/show" style="float: right;" class="linkToDispute">
                            <i aria-hidden="true" class="fa fa-list"></i>
                            {{activeLanguage.return}}
                        </router-link>
                    </h2>
                    <table class="table">
                        <thead class="thead-dark">
                        <tr>
                            <th scope="col">{{activeLanguage.infoTable.consum}}</th>
                            <th scope="col">{{activeLanguage.infoTable.ws}}</th>
                            <th scope="col">{{activeLanguage.infoTable.inv}}</th>
                            <th scope="col">{{activeLanguage.infoTable.resolved}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td scope="row">{{ group.dispute ? group.dispute[0].customer.email : '' }}</td>
                            <td>{{ group.dispute ? group.dispute[0].client.username : '' }}</td>
                            <td>{{ group.invoiceNr }}</td>
                            <td>{{ group.resolved ? activeLanguage.infoTable.resolvedY : activeLanguage.infoTable.resolvedN }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12">
                    <h2 v-if="manageOrdersPermissions">
                        {{activeLanguage.disputeTable.title}}
                        <router-link style="float: right;" :to="`/dispute/employee/create/${group.id}`" v-if="!group.resolved" class="btn">
                            {{activeLanguage.addNew}}
                        </router-link>
                    </h2>
                    <table class="table">
                        <thead class="thead-dark">
                        <tr>
                            <th scope="col">{{activeLanguage.disputeTable.category}}</th>
                            <!-- <th scope="col">{{activeLanguage.disputeTable.subject}}</th> -->
                            <th scope="col">{{activeLanguage.disputeTable.react}}</th>
                            <th scope="col">{{activeLanguage.disputeTable.who}}</th>
                            <th v-if="manageOrdersPermissions"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="dispute in group.dispute"
                            v-if="!dispute.hidden"
                            :class="dispute.resolved ? 'resolved' : ''"
                        >
                            <td @click="goToDispute(dispute)" scope="row">{{ dispute.category }}</td>
                            <!-- <td @click="goToDispute(dispute)">{{dispute.freshDeskTicket}}</td> -->
                            <td @click="goToDispute(dispute)">{{dispute.day}}</td>
                            <td @click="goToDispute(dispute)">{{dispute.owner.username}}</td>
                            <td v-if="manageOrdersPermissions" class="content-card content-card-total">
                                <button
                                        type="button"
                                        class="btn"
                                        @click="openResolveModalMessage(dispute)"
                                        :disabled="group.resolved"
                                >
                                    {{ dispute.resolved ? activeLanguage.disputeTable.reopen : activeLanguage.disputeTable.resolve }}
                                </button>
                                <MessageModal
                                    :activeLanguage="activeLanguageMsgModal.history.mesModal"
                                    :id="dispute.id"
                                    :ref="`msgModal-${dispute.id}`"
                                    @lastMessageHasAdded="resolveToggle(dispute)"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"
	import MessageModal from '../../../components/disputeView/modals/MessageModal'

	export default {
		name: "DisputeEmployeeShow",
		data() {
			return {
				group: {},
				uploadReady: true
			}
		},
		computed: {
			...mapGetters({
				activeLanguage: 'getDisputeShowLanguage',
				activeLanguageMsgModal: 'getDisputeDetailsLanguage',
				manageOrdersPermissions: 'userManage_orders',
            })
		},
		methods: {
            goToDispute(dispute) {
                window.open(`/order/${dispute.invoice}?openDispute=true&id=${dispute.id}`, '_blank');
                
            },
			getDisputes() {
                let queryString = window.location.search
                let searchParams = new URLSearchParams(queryString)
                let groupId = searchParams.get('groupId')
				disputeAxios
					.get('/api/employee-dispute/group-view/' + groupId)
					.then(({ data }) => {
						this.group = data
                        this.group.dispute.forEach((item) => {
                        	if (item.currentStep) {
		                        let a = new Date(item.currentStep.notificationDay.date).getTime(),
			                        b = new Date().getTime(),
			                        c = parseInt((a - b) / 86400000)

		                        item.day = c <= 0 ? 'Today' : `${c} Day(s) Left`;
                            } else {
                        		item.day = 'No Content'
                            }
                        })
						this.getOrderDetails()
                    })
					.catch(err => console.error(err))
			},
			openResolveModalMessage(dispute) {
                if (!dispute.resolved) {
	                this.$refs[`msgModal-${dispute.id}`][0].openResolveModalMessage()
                } else {
                	this.resolveToggle(dispute)
                }
			},
			resolveToggle(dispute) {
				disputeAxios
					.patch(`/api/employee-dispute/${dispute.resolved ? 'reopen' : 'resolve'}/${dispute.id}`)
					.then(({data}) => {
						if (data.code === 2010) {
							dispute.resolved = true

							if (data.orderCanBeReopened) {
								this.setOrderResume()
                                this.group.resolved = true
							}
						}
						else if (data.code === 2012) { dispute.resolved = false }
					})
					.catch(err => console.error(err))
			},
			setOrderResume() {
				billinkAxios
					.post('app/order/resume', { order_id: this.order_id })
					.then(({ data }) => {})
					.catch(err => console.error(err))
			},
			getOrderDetails() {
				billinkAxios
					.get(`app/order-details/${this.group.invoiceNr}`)
					.then(({ data }) => {
						this.order_id = data.id
					})
					.catch(error => console.error(error))
            }
		},
		mounted() {
			setTimeout(() => {
				this.getDisputes()
            }, 0)
        },
        components: {
	        MessageModal
        }
	}
</script>
<style lang="scss">
    .content-card-total {
        .modal-body{
            text-align: left;
        }
        .modal-header {
            background: #032438;
        }
        .modal-header, .modal-title,
        .modal-header .close{
            color: #ffffff;
        }
    }
</style>
<style scoped lang="scss">
    th,td {
        vertical-align: middle !important;
        text-align: center;
    }
    td {
        cursor: pointer;
    }
    .resolved {
        background: #ccf0ef;
    }
    .linkToDispute {
        font-size: 16px;
        font-weight: 400;
        i {
            font-size: 24px;
            margin-right: 5px;
            top: 4px;
            position: relative;
            color: #f48d21;
        }
    }
</style>