<template>
    <b-modal v-model="msgModal" hide-footer :title="activeLanguage.header" size="lg">
        <div class="row">
            <div class="col-12">
                <strong>{{ lastMessage ? 'Reason for resolving the dispute' : activeLanguage.title }}:</strong>
                <div>
                     <textarea
                       class="contentTextarea"
                       v-model="dispute.message"
                       :disabled="disabled"
                       :style="{width: '100%'}"
                     ></textarea>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 30px;">
            <div class="col-6 file-wr" :class="wrongExt ? 'err' : ''">
                <div>{{activeLanguage.ext}}</div>
                <b-form-file
                    @change="imageUpload($event, dispute)"
                    :disabled="disabled"
                    ref="fileInput"
                    :placeholder="activeLanguage.placeholder"
                    :lang="activeLanguage.uploadBtn === 'Browse' ? 'en' : 'du'"
                >
                </b-form-file>
            </div>
            <div class="col-6">
                <button
                        type="button"
                        class="btn"
                        style="float: right;"
                        @click="sendMessage(dispute)"
                        :disabled="disabled"
                >
                    {{activeLanguage.sendBtn}}
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
	export default {
		name: "MessageModal",
        props:['activeLanguage', 'id', 'disabled'],
        data() {
			return {
				msgModal: false,
				dispute: {
					message: '',
					fileName: '',
					file: null,
				},
				wrongExt: false,
				lastMessage: false,
				type: null
            }
        },
        methods: {
	        openResolveModalMessage(type) {
				if (type === 'closing') {
					this.type = "closing"
				} else {
					this.type = null
				}
		        this.lastMessage = true
		        this.msgModal = true
	        },
	        imageUpload(e, dispute) {
            if (e.target.files[0].size > 25000000) {
              this.$refs.fileInput.reset()
              EventBus.$emit('showAlertModal', this.activeLanguage.fileSizeError)
              return false
            }
		        if (e.target.files.length) {
			        if (['png', 'jpg', 'gif', 'jpeg', 'svg', 'pdf', 'xls', 'doc', 'docx', 'csv', 'txt'].includes(e.target.files[0].name.split('.').pop().toLowerCase())) {
				        this.wrongExt = false
				        let reader = new FileReader()
				        reader.readAsDataURL(e.target.files[0])
				        reader.onload = () => {
					        dispute.fileName = e.target.files[0].name
					        dispute.file = reader.result.slice(reader.result.indexOf('base64') + 7, reader.result.length)
				        }
			        } else {
				        this.$refs.fileInput.reset()
				        this.wrongExt = true
			        }
		        }
	        },
	        sendMessage(dispute) {
		        if (dispute.message.length === 0) {
			        EventBus.$emit('showAlertModal', this.activeLanguage.messageRequiredError)
			        return false
		        }

		        let data = {
			        dispute: this.id,
			        message: dispute.message,
			        fileName: dispute.fileName,
			        file: dispute.file,
					    type: this.type
		        }

		        disputeAxios
			        .post(`/api/employee-dispute/content/add`, data)
			        .then(({ data }) => {
				        if ( data.code === 2008 ) {
					        EventBus.$emit('showAlertModal', this.activeLanguage.emailTemplateVariablesError)
					        this.openMsgModal()
				        } else if (data.code === 2024) {
					        EventBus.$emit('showAlertModal', data.message)
					        this.$refs.fileInput.reset()
                        } else {
					        this.lastMessage ? this.$emit('lastMessageHasAdded', this.id) : this.$emit('mesAdded', data)

					        dispute.message = ''
					        dispute.file = null
					        this.wrongExt = false
					        this.$refs.fileInput.reset()
					        this.openMsgModal()
					        this.$store.dispatch('updateTasks')
				        }
			        })
			        .catch(err => console.error(err))
	        },
	        openMsgModal(type) {
				if (type === 'closing') {
					this.type = "closing"
				} else {
					this.type = null
				}
		        this.msgModal = !this.msgModal
		        this.lastMessage = false
	        },
        },
        watch: {
	        msgModal: function(newVal, oldVal) {
		        this.dispute.message = ''
		        this.wrongExt = false
            if (this.$refs.fileInput) {
              this.$refs.fileInput.reset()
            }
	        }
        }
	}
</script>

<style scoped>

</style>